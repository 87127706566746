<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg visitor-management-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("visitor-management", "reception-desks", "host")
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section class="hosts" v-if="userList && userList.length">
      <ul class="clebex-item-section pill">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="user in userList"
          :key="user.id"
        >
          <button class="clebex-item-content-wrapper" @click="selectHost(user)">
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-hea1-arrow-left" width="12" height="12"></icon>
              </span>
            </span>
            <span class="label">
              <span class="highlight"
                >{{ user.first_name }} {{ user.last_name }}</span
              >
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "SelectHost",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    actionType: {
      type: String,
      required: false
    },
    backLinkName: {
      type: String,
      required: true
    }
  },
  created() {
    if (!this.userList) {
      this.getCompanyUserList();
    }
  },
  computed: {
    ...mapState("user", ["userList"]),
    ...mapState("visit", ["visit"])
  },
  methods: {
    ...mapActions("user", ["getCompanyUserList"]),
    selectHost(host) {
      this.$store.commit(
        "visit/setVisit",
        { ...this.visit, host: host },
        { root: true }
      );

      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  }
};
</script>
